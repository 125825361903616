<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="栏目名称:">
                    <el-input v-model="queryParams.name" placeholder="请输入栏目名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="name" label="栏目名称" align="center" width="150"></el-table-column>
                <el-table-column label="logo" align="center" width="80" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img1')" style="height:23px;width:30px;" fit="cover" :class="`img1column${scope.$index}`" :src="scope.row.logo" :preview-src-list="[scope.row.logo]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="海报" align="center" width="80" prop="image">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img2')" style="height:23px;width:30px;" fit="cover" :class="`img2column${scope.$index}`" :src="scope.row.img" :preview-src-list="[scope.row.img]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="栏目数量" prop="num" align="center" width="120"></el-table-column>
                <el-table-column label="栏目介绍" prop="desc" align="center"></el-table-column>
                <el-table-column label="是否显示" prop="is_show" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.is_show === 1">不显示</el-tag>
                        <el-tag type="primary" v-if="scope.row.is_show === 2">显示</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否官方" prop="official" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.official === 1">非官方</el-tag>
                        <el-tag type="primary" v-if="scope.row.official === 2">官方</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="当前角标" prop="ty" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.ty === 0">无</el-tag>
                        <el-tag type="primary" v-if="scope.row.ty === 1">推荐</el-tag>
                        <el-tag type="primary" v-if="scope.row.ty === 2">热门</el-tag>
                        <el-tag type="primary" v-if="scope.row.ty === 3">火</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 3">已下架</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="栏目名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入栏目名称"></el-input>
                </el-form-item>
                <!-- 图片上传 -->
                <el-form-item label="logo" prop="logo">
                    <el-input v-model="form.logo" class="form-input" placeholder="请输入图片链接" style="margin-bottom: 20px;"></el-input>
                    <el-button @click.prevent="choiceImg('logo')">选择图片</el-button>
                    <el-upload :class="[form.logo ?'avatar-uploader1':'avatar-uploader','img1','up']" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                        <img v-if="form.logo" :src="form.logo" class="logo" />
                        <i v-else class="el-icon-plus logo-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="海报" prop="img">
                    <el-input class="form-input" v-model="form.img" placeholder="请输入封面图链接" style="margin-bottom: 20px;"></el-input>
                    <el-button @click.prevent="choiceImg('img')">选择图片</el-button>
                    <el-upload :class="[form.img ?'avatar-uploader1':'avatar-uploader','img2','up']" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleThumbSuccess" :before-upload="beforeThumbUpload" name="file" :headers="header">
                        <img v-if="form.img" :src="form.img" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="栏目介绍" prop="desc">
                    <el-input v-model="form.desc" placeholder="请输入栏目介绍"></el-input>
                </el-form-item>
                <el-form-item label="当前角标" prop="ty">
                    <el-radio-group v-model="form.ty">
                        <el-radio :label="0">无</el-radio>
                        <el-radio :label="1">推荐</el-radio>
                        <el-radio :label="2">热门</el-radio>
                        <el-radio :label="3">火</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否显示" prop="is_show">
                    <el-radio-group v-model="form.is_show">
                        <el-radio :label="2">显示</el-radio>
                        <el-radio :label="1">不显示</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否官方" prop="official">
                    <el-radio-group v-model="form.official">
                        <el-radio :label="2">官方</el-radio>
                        <el-radio :label="1">非官方</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
import Picture from '@/components/Picture';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
let loads = new loadEvents();
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            text: 'image',
            defaultImg: require('../../assets/img/zhan.jpeg'),
            header: {},
            menuList: ['社区', '文章栏目'],
            queryParams: {
                page: 1,
                pagesize: 10
            },
            //验证规则
            rules: {
                name: [{
                    required: true,
                    message: '栏目名称不能为空',
                    trigger: 'blur'
                }],
                logo: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                img: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                num: [{
                    required: true,
                    message: '栏目数量不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[1-9]\d*$',
                    message: "只能输入非零的正整数"
                }],
                ty: [{
                    required: true,
                    message: '当前角标不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                is_show: [{
                    required: true,
                    message: '是否显示不能为空',
                    trigger: 'blur'
                }],
                official: [{
                    required: true,
                    message: '是否官方不能为空',
                    trigger: 'blur'
                }],
            },
            loading: false,
            pageInfo: {},
            addVisible: false,
            confirmVisible: false,
            confirmContent: '',
            title: '加载中',
            mode: 'add',
            form: {
                state: 2,
                is_show: 2,
                official: 2,
            },
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
    },

    //图片上传
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, this.text, url);
        },
        // logo上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open(`.img1 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        // 海报上传
        handleThumbSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'img', res.data.uri);
            }
        },
        beforeThumbUpload(file) {
            loads.open(`.img2 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        // 查看大图
        showBigImg(index, node) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`${node}column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/community/article/column/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        //新增
        add() {
            this.mode = 'add';
            this.title = '新增';
            this.form = {
                state: 2
            };
            this.multiple = true
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑';
            //处理时间
            this.form = Object.assign({}, row);
            this.form.num = this.form.num + ''
            this.addVisible = true;
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/community/article/column/add' : '/community/article/column/edit';
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/community/article/column/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

.span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 200px;
    line-height: 200px;
    text-align: center;
}
.dialog_box .logo-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 150px;
    height: 200px;
    line-height: 200px;
}
.dialog_box .logo {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

.dialog_box .el-button {
    margin-left: 20px;
}
</style>
